import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Card,
  Box,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Typography, Stepper, Step, StepLabel } from "@material-ui/core";
import { Link, Route, Switch } from "react-router-dom";
import Challenges from "./challenge";
import { HOST_URL } from "../action/api";
import LockIcon from "@material-ui/icons/Lock";
import watermark from "../../src/dashboard/assets/watermark.png";
import GameDescription from "./GamesDescription";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { calculateDiscountedPrice } from "../utils";
import CryptoJS from "crypto-js";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: "grid",
    // gap: "20px", // Adjust the spacing as needed
    gap: theme.spacing(3),
    gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Responsive grid columns
  },
  
  root: {
    maxWidth: "100%",
    "& .MuiStepper-alternativeLabel": {
      backgroundColor: "inherit",
    },
    "& .MuiStepper-root": {
      flexWrap: "wrap",
    },
  },

  media: {
    height: 140,
    paddingTop: "56.25%",
  },
  card: {
    maxWidth: 300,
    minWidth: 270,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    height: "100%",
  },
  lablearro: {
    overflow: "inherite",
    maxWidth: 300,
    margin: "auto",
    height: '100%',
  },
  content: {
    textAlign: "left",
  },
  heading: {
    fontWeight: "bold",
  },
  subheading: {
    lineHeight: 1.8,
  },
  LabelImportantIcon: {
    position: "relative",
    top: "-260px",
    left: "268px",
    bottom: 0,
    right: "0px",
    fontSize: "50px",
  },
  lockIcon: {
    background: "#11568e", // Use a brighter color of your choice, e.g., #FFCC00
    color: "#FFFFFF",
    width: "60px",
    height: "60px",
    margin: "auto",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: -155,
  },
  boxBtn: {
    maxWidth: 200,
    minWidth: 270,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
}));

export default function Levels(props) {
  return (
    <>
      <Switch>
        <Route
          path="/dashboard/levels/tasks"
          render={(props) => (
            <Challenges
              active_task={props?.location.state.active_task}
              content={props?.location.state.challenges}
              levels={props?.location.state.levels}
            />
          )}
        />
        <Route>
          <LevelF game={props} />
        </Route>
      </Switch>
    </>
  );
}

function LevelF(props) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState("");
  const { levels } = props.game;

  useEffect(() => {
    setDisabled(levels.activelevel.length);
  }, [levels.activelevel.length]);

  return (
    <div className={classes.root}>
      <GameDescription gameDescription={props.game} />
      <Stage steps={{ steps: levels.levels, disabled: disabled }} />
      <div className={classes.gridContainer}>
      {/* <Grid container spacing={3}> */}
        {levels.levels.map((data, index) => (
          
          <Level
            key={index}
            level={{ data: data, index: index, disabled: disabled }}
            game_name={props.game.levels.game_name}
          />
        ))}
        </div>
      {/* </Grid> */}
    </div>
  );
}

function Stage(props) {
  const activeStep = props?.steps.disabled - 1;

  return (
    <Stepper alternativeLabel activeStep={activeStep}>
      {props?.steps.steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>Level - {label.level_number}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
// function ReplaceQueryParam({ decryptedData, paramNameToReplace, replacementValue }) {
//   const [updatedUrl, setUpdatedUrl] = useState("");

//   useEffect(() => {
//     const replaceQueryParam = (url, paramName, replacement) => {
//       try {
//         const parsedUrl = new URL(url);
//         parsedUrl.searchParams.set(paramName, replacement);
//         return parsedUrl.toString();
//       } catch (error) {
//         console.error("Error constructing URL:", error);
//         return url; // Return the original URL in case of an error
//       }
//     };

//     try {
//       const newUrl = replaceQueryParam(decryptedData, paramNameToReplace, replacementValue);
//       setUpdatedUrl(newUrl);
//     } catch (error) {
//       console.error("Error updating URL:", error);
//     }
//   }, [decryptedData, paramNameToReplace, replacementValue]);

//   return null;
// }
// function ReplaceQueryParam({ decryptedData, paramNameToRemove}) {
//   const replaceQueryParam = (url, paramName) => {
//     try {
//       const parsedUrl = new URL(url);
//       parsedUrl.searchParams.set(paramName);
//       return parsedUrl.toString();
//     } catch (error) {
//       console.error("Error constructing URL:", error);
//       return url; // Return the original URL in case of an error
//     }
//   };

//   return replaceQueryParam(decryptedData, paramNameToRemove);
// }


function Level(props) {
  const classes = useStyles();
  const history = useHistory();
  const [width, setInnerWidget] = useState(window.innerWidth);
  const encryptedImageURL = props?.level.data.photo;
  // const [decryptedData, setDecryptedData] = useState("");
  const [updatedUrl, setUpdatedUrl] = useState("");
  // const updatedUrl = ReplaceQueryParam({
  //   decryptedData,
  //   paramNameToReplace: "AWSAccessKeyId",
  //   replacementValue: "AKIASJIIGJEQXVFQEKYR",
  // });
  
  // useEffect(() => {
  //   // Log the updated URL
  //   console.log("Updated URL:", updatedUrl);
  // }, [updatedUrl]);
  const [decryptedData, setDecryptedData] = useState("");
  useEffect(() => {
    const encryptedData = props?.level.data.photo;
    const base64Key ='jW1DMN6Gd62k4pQdssZBG0y1M7SldkWZTYIEENToswE=';

    const decryptData = () => {
      const key = CryptoJS.enc.Base64.parse(base64Key);

      const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
      const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
      const ciphertext = CryptoJS.lib.WordArray.create(
        dataBytes.words.slice(4)
      );

      const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
      const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
      setDecryptedData(decryptedText);
    };

    decryptData();
  }, [props.level.data.photo]);
  
 
  // useEffect(() => {
  //   // Update the URL when decryptedData changes
  //   const url = ReplaceQueryParam({
  //     decryptedData,
  //     paramNameToReplace: "AWSAccessKeyId",
  //     replacementValue: "AKIASJIIGJEQXVFQEKYR",
  //   });
  //   setUpdatedUrl(url);
  // }, [decryptedData]);
  const fixWidth = () => {
    setInnerWidget(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", fixWidth);
    return () => {
      window.removeEventListener("resize", fixWidth);
    };
  }, []);

  const handleOpenBuyNowPage = (details) => {
    history.push({
      pathname: "/dashboard/buynow",
      levelDetails: details,
    });
  };

  

    // console.log("Rendering Level component with decrypted URL:", decryptedImageURL);
  return (
    <>
    {/* <ReplaceQueryParam
        decryptedData={decryptedData}
        paramNameToReplace="AWSAccessKeyId"
        replacementValue="AKIASJIIGJEQXVFQEKYR"
      /> */}
      {" "}
      <Grid xs item sm={width < 700 ? 12 : 6} md={4} lg={3}>
        <div className={classes.lablearro}>
          <Card className={classes.card}>
            <CardHeader
              style={{ textAlign: "center" }}
              title={
                props?.level.data.level_name.length > 15
                  ? props?.level.data.level_name
                  : props?.level.data.level_name
              }
              titleTypographyProps={{
                style: {
                  color: "#4a94d0",
                  fontSize: "24px",
                  fontWeight: "bold",
                },
              }}
            />
            <CardMedia
              className={classes.media}
              // image ={decryptedData}
              image={decryptedData}
              // image={props?.level.index > props?.level.disabled - 1 ? updatedUrl : null}
  //             onError={(e) => {
  //   console.error(`Error loading image: ${e}`);
  //   // Handle image loading error (e.g., show a placeholder image)
  // }}
    
                  // ? `${HOST_URL}${props?.level.data.photo}`
                  // : watermark
              // }
              style={{
                opacity:
                  props?.level.index > props?.level.disabled - 1 ? 0.25 : 1,
              }}
            />
            <CardContent
              className={classes.content}
              style={{
                opacity:
                  props?.level.index > props?.level.disabled - 1 ? 0.65 : 1,
              }}
            >
              <Typography
                style={{
                  textAlign: "center",
                  color: "#ee383a",
                  fontSize: "25px",
                  fontWeight: "bold",
                }}
              >
                {props?.level.data.level_code}
              </Typography>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h6"}
                gutterBottom
                style={{ fontSize: "18px", fontWeight: "bold" }}
              >
                Level : {props?.level.data.level_number}
              </Typography>
              {props?.level.index > props?.level.disabled - 1 ? (
                <p variant="h6" sx={{ mr: 1 }}>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <span style={{ fontWeight: "bold" }}>Price :</span>
                    <span
                      style={{
                        color: "#6c757d",
                        textDecoration: "line-through",
                        marginRight: "0.5rem",
                      }}
                    >
                      {props?.level.data.price}
                    </span>
                    <span style={{ color: "#6c757d", fontWeight: "bold" }}>
                      {Math.trunc(
                        calculateDiscountedPrice(
                          props?.level.data.price,
                          props.level.data.discount
                        )
                      )}{" "}
                      INR
                    </span>
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    <span style={{ color: "#6c757d" }}>
                      Save {props?.level.data.discount}% with this discount
                      offer!
                    </span>
                  </Typography>
                </p>
              ) : (
                props?.level.data.tasks.map((item, index) => (
                  <Typography variant="body1">
                    <span style={{ fontStyle: "italic" }}>
                      {/* task-{index + 1} : {item.task_name} */}
                      {/* task-{index + 1} : {item.task_name} */}
                    </span>
                  </Typography>
                ))
              )}
              {props?.level.index > props?.level.disabled - 1 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  position="relative"
                >
                  <Box className={classes.lockIcon}>
                    <LockIcon />
                  </Box>
                </Box>
              )}
            </CardContent>
            <CardActions>
              {!(props?.level.index > props?.level.disabled - 1) ? (
                <Button style={{ marginLeft: "auto", marginTop:"21%" }} variant="contained">
                  <Link
                    to={{
                      pathname: `/dashboard/levels/tasks`,
                      state: {
                        active_task: props?.level.data.active_task,
                        challenges: props?.level.data.tasks,
                        levels: props,
                      },
                    }}
                  >
                    Open Tasks
                  </Link>
                  
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disableElevation
                  // fullWidth
                  style={{ marginLeft: "auto" }}
                  onClick={() => handleOpenBuyNowPage(props)}
                >
                  Buy Now
                </Button>
                
              )}
            </CardActions>
          </Card>
        </div>
      </Grid>
      
    </>
  );
}
