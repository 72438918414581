import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./stemKit.css";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";

import Image1 from '../../components/Images/image1.png';
import Image2 from '../../components/Images/image2.png';
import Image3 from '../../components/Images/image3.png';
import Image4 from '../../components/Images/image4.png';
import Image5 from '../../components/Images/image5.png';
import Image6 from '../../components/Images/image6.png';
import Image7 from '../../components/Images/image7.png';
import Image8 from '../../components/Images/image8.png';
import Image9 from '../../components/Images/image9.png';

export default function StemKit() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        // effect={"cube"}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 150,
          modifier: 2,
          slideShadows: true,
        }}
        breakpoints={{
          340: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          1600: {
            slidesPerView: 7/2,
            spaceBetween: 50,
          },
        }}
        modules={[EffectCoverflow, Autoplay, Navigation]}
        // modules={[EffectCube, Autoplay, Navigation]} 
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Image1} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image2} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image3} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image4} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image5} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image6} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image7} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image8} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image9} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image1} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image2} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image3} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image4} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image5} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image6} alt="img" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={Image7} alt="img" />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
