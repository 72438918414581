import React, { Component } from "react";
import { Container, Row, Col, NavLink } from "reactstrap";
// import Islp from "../../pages/NewIslp/section";
//import { Link } from "react-router-dom";

class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="features">
          <Container>
            <Row className="vertical-content">
              <Col lg={5}>
                <div className="features-box">
                  <h3>{this.props.Islp ? "About ISPL" : "About Us"}</h3>
                  {/* <p className="text-muted web-desc">
                    {this.props.Islp
                      ? `ILP STEM Premier league(ISPL) is National level Open STEM 
                      competitions in various stages throughout India. ISPL will 
                      complete in two  phases where  participants will build & create 
                      STEM models using basic STEM(Science, Technology, Engineering, 
                        Mathematics ) concepts with help ILearnPlace - Innovative 
                        Insight in Action  STEM learning platform.    Team has to be 
                        register to  ilearnplace.com for ISPL-2023 with STEM idea in 
                        phase-1. Team will submit recorded video after completion of 
                        STEM model to ilearnplace dashboard. Top 10 team will awarded by  
                        awards which will be worth of 5 Lacs.`
                      : `Almondai Technologies Pvt. Ltd (ilearnplace) is an education STEM gaming company combines the fun and engagement of gaming with the educational benefits of STEM (Science, Technology, Engineering, and Mathematics) learning. Here are some reasons why such a company could be beneficial:`}
                  </p> */}
                  <p className="text-muted web-desc">
                    {this.props.Islp
                    ? `iLearnPlace STEM Premier League (ISPL) is an open STEM competition.
                     ISPL will consist of two phases where participants will build and create STEM models using basic STEM (Science, Technology, Engineering, Mathematics) concepts with the help of the iLearnPlace STEM kit.
                      After building, teams can participate in the Relay Robo Race with the kit.
                       Teams must register for ISPL-2024 at ilearnplace.com, and they have the chance to win up to a 50,000 prize.`
                    :`Almondai Technologies Pvt. Ltd (ilearnplace) is an education STEM gaming company combines the fun and engagement of gaming with the educational benefits of STEM (Science, Technology, Engineering, and Mathematics) learning. Here are some reasons why such a company could be beneficial:`}
                  </p>
                  {this.props.Islp ? (
                    <ul className="text-muted list-unstyled mt-4 features-item-list">
                      <li className="">
                        Critical thinking and problem solving.
                      </li>
                      <li className="">Creativity and innovation.</li>
                      <li className="">Teamwork and collaboration.</li>
                      <li className="">
                        Respect for diversity and inclusivity.
                      </li>
                      <li className="">Passion for learning.</li>
                    </ul>
                  ) : (
                    <ul className="text-muted list-unstyled mt-4 features-item-list">
                      <li className="">Increased engagement and motivation</li>
                      <li className="">Enhanced learning experience</li>
                      <li className="">Accessible and scalable</li>
                      <li className="">Future job prospects</li>
                      <li className="">Fun and entertaining</li>
                    </ul>
                  )}
                  <NavLink
                    href="#pricing"
                    // href="game-details/:id"
                    className="btn btn-primary mt-4 waves-effect waves-light"
                  >
                    Learn More <i className="mdi mdi-arrow-right"></i>
                  </NavLink>
                </div>
              </Col>
              <Col lg={7}>
                <div className="features-img features-right text-right">
                  <iframe
                    width="460"
                    height="315"
                    src="https://www.youtube.com/embed/KHi6X_Srplc?autoplay=1&mute=1&enablejsapi=1"
                    allow="autoplay"
                    frameborder="0"
                    allowfullscreen
                    title="video"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUs;
